import { trackEvent } from "services/moengage.service";
import feed_mo_engage from "../../assets/mo_engage/feed.json";

const events = feed_mo_engage.entity;

export const moEngageFeedQuickLink = (attributes = {}) => {
    trackEvent(events.Feed_Quick_Link.event_name, attributes);
};

// feed card
export const moEngageFeedLikeIcon = (attributes = {}) => {
    trackEvent(events.Feed_Like.event_name, attributes);
};

export const moEngageFeedCommentIcon = (attributes = {}) => {
    trackEvent(events.Feed_Detail.event_name, attributes);
};

export const moEngageFeedCommentSend = (attributes = {}) => {
    trackEvent(events.Feed_Comment.event_name, attributes);
};

export const moEngageFeedLikeOnComment = (attributes = {}) => {
    trackEvent(events.Feed_Like_On_Comment.event_name, attributes);
};

export const moEngageFeedReplyOnComment = (attributes = {}) => {
    trackEvent(events.Feed_Reply_On_Comment.event_name, attributes);
};

export const moEngageFeedSaveIcon = (attributes = {}) => {
    trackEvent(events.Feed_Bookmark.event_name, attributes);
};

export const moEngageFeedShareIcon = (attributes = {}) => {
    trackEvent(events.Feed_Share.event_name, attributes);
};

export const moEngageFeedShareCopy = (attributes = {}) => {
    trackEvent(events.Feed_Share_Copy.event_name, attributes);
};

export const moEngageFeedFollowClick = (attributes = {}) => {
    trackEvent(events.Follow_User.event_name, attributes);
};

// Feed Search
export const moEngageSearchFeedIcon = (attributes = {}) => {
    trackEvent(events.Search_Feed.event_name, attributes);
};

export const moEngageSearchFeed = ({ search_text_value }) => {
    const { search_text } = events.search_feed.attributes_search_feed;

    const attributes = {
        [search_text]: search_text_value,
    }
    trackEvent(events.search_feed.event_name, attributes);
};

export const moEngageSearchFeedCancel = (attributes = {}) => {
    trackEvent(events.Cancel_Search_Feed.event_name, attributes);
};

// Create Feed Modal
export const moEngageFeedCreatePost = (attributes = {}) => {
    trackEvent(events.Feed_Create_Post.event_name, attributes);
};

export const moEngageCreatePostImage = (attributes = {}) => {
    trackEvent(events.Create_Post_Image.event_name, attributes);
};

export const moEngageCreatePostVideo = (attributes = {}) => {
    trackEvent(events.Create_Post_Video.event_name, attributes);
};

export const moEngageCreatePostDocument = (attributes = {}) => {
    trackEvent(events.Create_Post_Document.event_name, attributes);
};

export const moEngageFeedCreatePostAddTag = (attributes = {}) => {
    trackEvent(events.Create_Post_Add_Tag.event_name, attributes);
};

export const moEngageCreatePostSearchTag = ({ search_text_value }) => {
    const { search_text_key } = events.Create_Post_Search_Tag.attributes_Create_Post_Search_Tag;

    const attributes = {
        [search_text_key]: search_text_value,
    }
    trackEvent(events.Create_Post_Search_Tag.event_name, attributes);
};

export const moEngageCreatePostSaveTag = (attributes = {}) => {
    trackEvent(events.Create_Post_Save_Tag.event_name, attributes);
};

export const moEngageCreatePostCancelTag = (attributes = {}) => {
    trackEvent(events.Create_Post_Cancel_Tag.event_name, attributes);
};

export const moEngagePostCreated = (attributes = {}) => {
    trackEvent(events.Post_Created.event_name, attributes);
};

export const moEngageCreatePostTagAdded = ({ tag_name_value }) => {
    const { tag_name_key } = events.Create_Post_Tag_Added.attributes_Create_Post_Tag_Added;

    const attributes = {
        [tag_name_key]: tag_name_value,
    }
    trackEvent(events.Create_Post_Tag_Added.event_name, attributes);
};

export const moEngageCreatePostTagRemoved = ({ tag_name_value }) => {
    const { tag_name_key } = events.Create_Post_Tag_Removed.attributes_Create_Post_Tag_Removed;

    const attributes = {
        [tag_name_key]: tag_name_value,
    }
    trackEvent(events.Create_Post_Tag_Removed.event_name, attributes);
};


// Feed medias
export const moEngagePostImageAttached = (attributes = {}) => {
    trackEvent(events.Post_Image_Attached.event_name, attributes);
};

export const moEngagePostVideoAttached = (attributes = {}) => {
    trackEvent(events.Post_Video_Attached.event_name, attributes);
};

export const moEngagePostDocumentAttached = (attributes = {}) => {
    trackEvent(events.Post_Document_Attached.event_name, attributes);
};

export const moEngageFeedUsernameClicked = (attributes = {}) => {
    trackEvent(events.Feed_Username_Click.event_name, attributes);
}

export const moEngageFeedCommentUsernameClicked = (attributes = {}) => {
    trackEvent(events.Feed_Comment_Username_Click.event_name, attributes);
}

export const moEngageFeedTrendingTagsClick = (attributes = {}) => {
    trackEvent(events.Feed_Trending_Tags_Click.event_name, attributes);
}

export const moEngageFeedSortTypeSelect = (attributes = {}) => {
    trackEvent(events.Feed_Sort_Type_Select.event_name, attributes);
}

export const moEngageFeedDetailSaveImage = (attributes = {}) => {
    trackEvent(events.Feed_Detail_Save_Image.event_name, attributes);
}

export const moEngageFeedDetailSaveVideo = (attributes = {}) => {
    trackEvent(events.Feed_Detail_Save_Video.event_name, attributes);
}

export const moEngageFeedDetailSaveDocument = (attributes = {}) => {
    trackEvent(events.Feed_Detail_Save_Doc.event_name, attributes);
}

export const moEngageFeedDetailUsernameClicked = (attributes = {}) => {
    trackEvent(events.Feed_Detail_Username_Click.event_name, attributes);
}

export const moEngageFeedDetailCommentUsernameClicked = (attributes = {}) => {
    trackEvent(events.Feed_Detail_Comment_Username_Click.event_name, attributes);
}

export const moEngageFeedDetailLikeIcon = (attributes = {}) => {
    trackEvent(events.Feed_Detail_like_icon.event_name, attributes);
}

export const moEngageFeedDetailCommentSend = (attributes = {}) => {
    trackEvent(events.Feed_Detail_comment_send.event_name, attributes);
}

export const moEngageFeedDetailCommentLike = (attributes = {}) => {
    trackEvent(events.Feed_Detail_comment_like.event_name, attributes);
}

export const moEngageFeedDetailCommentReply = (attributes = {}) => {
    trackEvent(events.Feed_Detail_comment_reply.event_name, attributes);
}

export const moEngageFeedDetailSaveIcon = (attributes = {}) => {
    trackEvent(events.Feed_Detail_save_icon.event_name, attributes);
}

export const moEngageFeedDetailShareIcon = (attributes = {}) => {
    trackEvent(events.Feed_Detail_share_icon.event_name, attributes);
}

export const moEngageFeedDetailShareCopy = (attributes = {}) => {
    trackEvent(events.Feed_Detail_share_copy.event_name, attributes);
}

export const moEngageFeedDetailFollowClick = (attributes = {}) => {
    trackEvent(events.Feed_Detail_follow_click.event_name, attributes);
}

export const moEngageFeedScrollTime = ({ scroll_time_value }) => {
    console.log('scroll_time_value', scroll_time_value)
    const { scroll_time_key } = events.Feed_scroll_time.attributes_Feed_scroll_time;

    const attributes = {
        [scroll_time_key]: scroll_time_value,
    }
    trackEvent(events.Feed_scroll_time.event_name, attributes);
};


export const moEngageFeedFeaturedClick = (attributes = {}) => {
    trackEvent(events.Feed_Featured_Click.event_name, attributes);
}

export const moEngageFeedFeaturedCarouselClick = (attributes = {}) => {
    trackEvent(events.Feed_Featured_Carousel_Click.event_name, attributes);
}

export const moEngageFeedWebinarPostClick = (attributes = {}) => {
    trackEvent(events.Feed_Webinar_Post_Click.event_name, attributes);
}

export const moEngageFeedQuizPostClick = (attributes = {}) => {
    trackEvent(events.Feed_Quiz_Post_Click.event_name, attributes);
}

export const moEngageFeedBlogPostClick = (attributes = {}) => {
    trackEvent(events.Feed_Blog_Post_Click.event_name, attributes);
}

export const moEngageFeedDetailTagClick = (attributes = {}) => {
    trackEvent(events.Feed_Detail_Tag_Click.event_name, attributes);
}